<template>
	<main></main>
</template>

<script>
// import signinWithPopup from "./msal";
export default {
	mounted() {
		// signinWithPopup();
	},
};
</script>
